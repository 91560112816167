import { SnoepSoep } from "./game";

export class Block extends Phaser.GameObjects.Sprite {
    row: number = 0;
    col: number = 0;

    // keepScale: number;

    game: SnoepSoep;
    constructor(x: number, y: number, data: any, scene: SnoepSoep, spaceBetween: number){
        super(scene, x, y, data.asset);

        // this.keepScale = SnoepSoep.config.blockSize / 35;

        this.game = scene;

        this.row = data.row;
        this.col = data.col;

        // this.setScale(this.keepScale);

        this.setOrigin(0.5);

        this.setDepth(5);

        this.setSize((SnoepSoep.config.blockSize + spaceBetween) * 1, (SnoepSoep.config.blockSize + spaceBetween) * 1);

        this.setDisplaySize(SnoepSoep.config.blockSize * 1, SnoepSoep.config.blockSize * 1);


        // this.setInteractive();

        // this.on('mousedown', () => this.game.pickBlock(this));

        // this.on('pointerup', () => {
        //     this.game.pickBlock(this);
        // });
    }

    pointerOver(x: number, y: number, first: boolean, spaceBetween: number){
        let xmin = this.getLeftCenter().x -spaceBetween;
        let xmax = this.getRightCenter().x + spaceBetween;
        let ymin = this.getTopCenter().y - spaceBetween;
        let ymax = this.getBottomCenter().y + spaceBetween;

        if(first){
            return (x > xmin && x < xmax && y > ymin && y < ymax);
        } else {
            if(this.col === 0){
                xmin -= 10;
            }
            if (this.col === (SnoepSoep.config.size - 1)){
                xmax += 10;
            }
            if(this.row === 0){
                ymin -= 200;
            }
            if (this.row === (SnoepSoep.config.size - 1)){
                ymax += 200;
            }
            return (x > xmin && x < xmax && y > ymin && y < ymax);
        }
    }

    reset(x: number, y: number, data: any) {
        // Phaser.Sprite.prototype.reset.call(this, x, y);
        this.setDepth(5);
        this.setAlpha(1);
        this.setPosition(x, y);
        this.setTexture(data.asset);
        this.row = data.row;
        this.col = data.col;
      };

    kill() {

        this.scene.add.tween({
            targets: this,
            duration: 125,
            scaleX: "/=2" ,
            scaleY: "/=2",
            repeat: 2,
            yoyo: true,
        })
        this.scene.add.tween({
            targets: this,
            duration: 250,
            alpha: 0,
            delay: 250
        })
        setTimeout(() => {
this.destroy(true)        }, 500)
    };
}